/* eslint-disable no-use-before-define */
import moment from 'moment';
import { LocaleObject } from '@nuxtjs/i18n';
import { StripeCardCvcElement, StripeCardExpiryElement, StripeCardNumberElement } from '@stripe/stripe-js';
import {
  MetaPropertyCharset,
  MetaPropertyEquiv,
  MetaPropertyMicrodata,
  MetaPropertyName,
  MetaPropertyProperty,
} from 'vue-meta';
import { Constant, RevisionCodeType } from '~/ts/config/enumerations';

export class ActivitiesMapFilter {
  search: string = '';
  'type.uuid': Array<string> = [];
  free: boolean = false;
  paid: boolean = false;
}

export interface Activity extends Identifier {
  address: Address;
  author: Person;
  authorLocale: Locale;
  certified: boolean;
  description: string | null;
  email: string | null;
  free: boolean;
  logo: Media | null;
  mainPicture: Media | null;
  medias: Array<Media> | undefined;
  ownerPro: Person;
  premiumEndDate: string;
  premiumStartDate: string;
  telephone: string;
  title: string | null;
  translations: { [key: string]: { description: string; locale: string; title: string } };
  type: Enumeration;
  website: string;
}

export class ActivityForm {
  '@id': string | null = null;
  address: AddressForm = new AddressForm();
  author: any = null;
  authorLocale: Locale | string = '';
  description: string | null = null;
  email: string | null = null;
  free: boolean | null = null;
  logo: any = null;
  mainPicture: any = null;
  medias: Array<any> = [];
  premiumEndDate: string | null = null;
  telephone: string | null = null;
  title: string | null = null;
  translations: { [key: string]: { description: string; locale: string; title: string } } = {};
  type: any = null;
  uuid: string | null = null;
  website: string | null = null;
}

export interface Ad extends Identifier {
  clicks: number;
  endDate: Date | null;
  name: string;
  startDate: Date | null;

  status: AdStatusType;
  views: number;
}

export type AdStatusType = 'draft' | 'on' | 'off';

export interface Address extends Identifier {
  addressAddIn?: string | null;
  cedex?: string | null;
  cityCode?: string | null;
  cityName: string;
  country: string;
  isoCode?: string | null;
  lat?: number | null;
  lat1?: number | null;
  lat2?: number | null;
  lat3?: number | null;
  latCardinality?: string | null;
  lng?: number | null;
  lng1?: number | null;
  lng2?: number | null;
  lng3?: number | null;
  lngCardinality?: string | null;
  streetName: string;
  zipCode: string;
}

export class AddressForm {
  addressAddIn?: string | null = null;
  cedex?: string | null = null;
  cityCode?: string | null = null;
  cityName: string = '';
  country: string = '';
  isoCode?: string | null = null;
  lat?: number | null = null;
  lat1?: number | null = null;
  lat2?: number | null = null;
  lat3?: number | null = null;
  latCardinality?: string | null = null;
  lng?: number | null = null;
  lng1?: number | null = null;
  lng2?: number | null = null;
  lng3?: number | null = null;
  lngCardinality?: string | null = null;
  streetName: string = '';
  zipCode: string = '';
}

export type AdvertiserTabItemsType = Array<{ description: string; img: string; title: string }>;

export type AdvertiserTabSectionType = {
  description: string;
  items: AdvertiserTabItemsType;
  title: string;
};

export interface AmbassadorItem {
  caras: number;
  description: string;
  icon: IconType;
}

export interface Attribute extends Identifier {
  category: Enumeration;
  favorite: boolean;
  id?: number;
  label: string;
  picto: Media;
  section: string | null;
  translations?: { [key: string]: AttributeTranslation };

  value: RevisionAttributeForm;
}

export class AttributeObject implements Attribute {
  '@id': string;
  id?: number;
  category: Enumeration;
  favorite: boolean;
  label: string;
  picto: Media;
  section: string | null;
  translations?: { [key: string]: AttributeTranslation };
  value: RevisionAttributeForm;
  uuid: string;

  constructor(attribute: Attribute) {
    this['@id'] = attribute['@id'];
    this.id = attribute.id;
    this.category = attribute.category;
    this.favorite = attribute.favorite;
    this.label = attribute.label;
    this.picto = attribute.picto;
    this.section = attribute.section;
    this.value = { attribute: attribute['@id'], detail: null, picto: null };
    this.uuid = attribute.uuid;
  }
}

export interface AttributeTranslation extends Identifier {
  label: string;
  locale: string;
  translatableId?: number;
}

export interface Award extends Identifier {
  code: string;
  picture: Media;
  year: number;
}

export interface BreadcrumbItem {
  isActive: boolean;
  label: string;
  position: number;
  url: string;
}

export type ButtonType = { [key in ButtonTypeEnum]?: boolean };

export type ButtonTypeEnum = 'comment' | 'delete' | 'edit' | 'share' | 'favorite' | 'picture' | 'print';

export interface CamperVan extends Identifier {
  brand: Enumeration | null;
  picture: string | null | Media;
  type: Enumeration | null;
  year: string | null;
}

export class CamperVanForm {
  '@id': string | null = null;
  brand: string | null = null;
  picture: string | null | Media = null;
  type: string | null = null;
  year: string | null = null;

  hydrate(data: CamperVan | null) {
    if (!data) return null;
    this['@id'] = data['@id'] || null;
    this.brand = data.brand?.['@id'] || null;
    this.picture = data.picture;
    this.type = data.type?.['@id'] || null;
    this.year = data.year;

    return this;
  }
}

export interface CampingInformation extends Identifier {
  animal: boolean;
  barbecue: boolean;
  ccPassage: boolean;
  ffcAdvantage: boolean;
  ffcAdvantageDescription: string | null;
  mandatoryParking: boolean;
  minimumSleep: number | null;
  naturist: boolean;
  notation: number | null;
  stopWelcome: boolean;
  stopWelcomeDescription: string | null;
  surface: string | null;
}

export interface CampingInformationForm {
  animal: boolean;
  barbecue: boolean;
  ccPassage: boolean;
  ffcAdvantage: boolean;
  ffcAdvantageDescription: string | null;
  mandatoryParking: boolean;
  minimumSleep: number | null;
  naturist: boolean;
  notation: number | null;
  stopWelcome: boolean;
  stopWelcomeDescription: string | null;
  surface: string | null;
}

export interface City {
  description: string;
  main: string;
  placeId: string;
}

export interface CityRevision {
  cityName: string;
  country: string;
  formatted: string;
  lat: number;
  lng: number;
  placeId: string;
  zipCode: string;
}

export interface Comment extends Translatable<CommentTranslation> {
  author: Person;
  authorLocale: Locale;
  children: Array<Comment>;
  createdAt: string;
  defaultValue: string;
  notation: number;
  parent?: Comment;
  pointOfInterest: PointOfInterest;
  value: string;
}

export interface CommentForm {
  notation: number;
  parent?: string;
  pointOfInterest: string;
  value: string;
}

export interface CommentOutput extends Identifier {
  author: Person;
  authorLocale: Locale;
  children: Array<CommentOutput>;
  createdAt: string;
  loading: boolean | undefined;
  notation: number;
  originalValue: string;
  requestedValue: string;
  translations: { [locale: string]: { value: string } };
  value: string;
}

export interface CommentTranslation extends Translation {
  value: string;
}

export type Config = {
  name: ConfigType;
  value: string;
};

export class ConfigClass {
  notation_users: string = '';
  notation_rate: string = '';
}

export type ConfigType = 'notation_users' | 'notation_rate';

export interface ContactForm {
  email: string;
  message: string;
  name: string;
  recipient: string;
  revisionName: string;
}

export interface ContactInformation extends Identifier {
  cityHall: string | null;
  email: string | null;
  email2: string | null;
  facebook: string | null;
  instagram: string | null;
  policeOffice: string | null;
  referentName: string | null;
  telephone: string | null;
  telephone2: string | null;
  touristOffice: string | null;
  twitter: string | null;
  website: string | null;
  website2: string | null;
}

export interface ContactInformationForm {
  cityHall: string | null;
  email: string | null;
  email2: string | null;
  facebook: string | null;
  instagram: string | null;
  policeOffice: string | null;
  referentName: string | null;
  telephone: string | null;
  telephone2: string | null;
  touristOffice: string | null;
  twitter: string | null;
  website: string | null;
  website2: string | null;
}

export interface Country extends Translatable<CountryTranslation> {
  alpha2: string | null;
  alpha3: string | null;
  countryCode: number | null;
  intermediateRegion: string | null;
  intermediateRegionCode: number | null;
  iso31665: string | null;
  name: string | null;
  region: string | null;
  regionCode: number | null;
  subRegion: string | null;
  subRegionCode: number | null;
}

export interface CountryTranslation extends Translation {
  name: string | null;
}

export interface CustomLocaleObject extends LocaleObject {
  name: string;
}

export interface DeleteAccountForm {
  contact: boolean;
  details: string | null;
  reason: string | null;
}

export interface DeleteCommentForm {
  isAnswer: boolean;
  uuid: string;
}

export interface Destination extends Translatable<DestinationTranslation> {
  cityName: string | null;
  country: Country | null;
  mapUrl: string | null;
  photo: Media | null;
  status: boolean;
}

export interface DestinationTranslation extends Translation {
  cityName: string | null;
}

export type DictionnaryType<T = any> = { [key: string]: T; [key: number]: T };

export interface DirectionInstructionOutput {
  distance: number;
  distanceString: string;
  duration: number;
  durationString: string;
  instruction: string;
}

export interface DirectionOutput {
  errorCouple: Array<[{ lat: number; lng: number }, { lat: number; lng: number }]>;
  polylines: Array<string>;
  steps: Array<DirectionStepOutput>;
  totalDistance: number;
  totalDuration: number;
  totalDurationString: string;
}

export interface DirectionStepOutput {
  distance: number;
  duration: number;
  durationString: string;
  endAddress: string;
  instructions: Array<DirectionInstructionOutput>;
  startAddress: string;
}

export interface DirectoryCountry extends Identifier {
  description: string;
  name: string;
  photo: Media | null;
  photoFile: File | null;
  reference: string | null;
  title: string;
  translations: { [key: string]: DirectoryCountryTranslation };
}

export interface DirectoryCountryTranslation {
  description: string | null;
  id: string | null;
  locale: string | null;
  name: string | null;
  title: string | null;
}

export interface DirectoryDepartment extends Identifier {
  country: DirectoryCountry | null;
  description: string;
  name: string;
  photo: Media | null;
  photoFile: File | null;
  reference: string | null;
  revisions: Array<Revision> | null;
  translations: { [key: string]: DirectoryDepartmentTranslation };
}

export interface DirectoryDepartmentTranslation {
  description: string | null;
  id: string | null;
  locale: string | null;
  name: string | null;
}

export interface DmsInterface {
  cardinality: string;
  degrees: number;
  minutes: number;
  seconds: number;
}

export interface EditCommentForm {
  notation: number;
  uuid: string;
  value: string;
}

export interface ElasticSearchActivityResult {
  items: Array<Activity>;
  lastPage: number;
  totalItems: number;
}

export interface ElasticSearchRevisionResult {
  items: Array<Revision>;
  lastPage: number;
  totalItems: number;
}

export interface ElasticSearchTripResult {
  items: Array<Trip>;
  lastPage: number;
  totalItems: number;
}

export class EmailShareForm {
  email: string = '';
  familyName: string = '';
  givenName: string = '';
  message: string = '';
  recipient: string = '';
  url: string = '';
}

export interface Enumeration<T = string> extends Identifier {
  code: T;
  enabled: boolean;
  position: number;
  section: string;
  value: string;
}

export interface FavoriteOutput {
  createdAt: Date;
  name: string;
  revision: Revision;
  type: Enumeration;
  uuid: string;
}

export type FilterType<T = any> = T;

export class FormClass<T> {
  '@id': string | null = null;

  hydrate(entity?: T | this | null): this {
    if (!entity) {
      return this;
    }

    // @ts-ignore
    Object.entries(this).forEach(([key, _value]: [keyof (T | this), any]) => {
      const data: any = entity[key];
      if (!data) return;

      this[key] = data;
    });

    return this;
  }

  format(): object {
    const object = JSON.parse(JSON.stringify(this));

    Object.entries(this).forEach(([key, value]) => {
      if (!value) return;

      if (typeof value === 'object' && '@id' in value && value['@id']) {
        object[key] = value['@id'];
      } else if (Array.isArray(value) && value[0] !== null && typeof value[0] === 'object' && '@id' in value[0]) {
        object[key] = value.map((val) => val['@id'] || val);
      }
    });

    return object;
  }
}

export interface GeoapifyAutocompleteResponse {
  city: string;
  country: string;
  formatted: string;
  housenumber: string;
  lat: number;
  lon: number;
  place_id: string;
  pluscode: string;
  postcode: string;
  street: string;
}

export interface GeocodedAddressInterface {
  administrative_area_level_1?: string;
  administrative_area_level_2?: string;
  country?: string;
  dms?: GeocodedDmsInterface;
  formatted_address?: string;
  locality?: string;
  plus_code?: string;
  position: google.maps.LatLng;
  postal_code?: string;
  postal_town?: string;
  route?: string;
  street_number?: string;
}

export interface GeocodedDmsInterface {
  lat: DmsInterface;
  lng: DmsInterface;
}

export interface HydraResponse {
  '@context'?: string;
  '@id'?: string;
  '@type'?: string;
}

export interface HydraView {
  '@id'?: string;
  '@type'?: string;
  'hydra:first'?: string;
  'hydra:last'?: string;
  'hydra:next'?: string;
}

export type IconType =
  | 'Ad'
  | 'Add'
  | 'AmbassadorAddArea'
  | 'AmbassadorAddPicture'
  | 'AmbassadorCommentPlace'
  | 'AmbassadorCompleteProfile'
  | 'AmbassadorLeftQuote'
  | 'AmbassadorLevel1'
  | 'AmbassadorLevel2'
  | 'AmbassadorLevel3'
  | 'AmbassadorLevel4'
  | 'AmbassadorLevel5'
  | 'AmbassadorLevel6'
  | 'AmbassadorLevel7'
  | 'AmbassadorLevel8'
  | 'AmbassadorRegister'
  | 'AmbassadorRightQuote'
  | 'AmbassadorUpdateArea'
  | 'ArrowDownCircle'
  | 'ArrowRight'
  | 'ArrowRightThin'
  | 'ArrowUpWideShort'
  | 'Briefcase'
  | 'Camera'
  | 'CameraPlusOutline'
  | 'Caras'
  | 'CarasOutline'
  | 'Caravan'
  | 'Check'
  | 'ClockTimeNineOutline'
  | 'Cog'
  | 'Comment'
  | 'Compass'
  | 'Copy'
  | 'Cross'
  | 'Crown'
  | 'Download'
  | 'Email'
  | 'EmptyHeart'
  | 'Facebook'
  | 'Filter'
  | 'FingerUp'
  | 'Heart'
  | 'Help'
  | 'Home'
  | 'LikedComment'
  | 'Loading'
  | 'LocationArrow'
  | 'MapPoint'
  | 'More'
  | 'Pencil'
  | 'Planet'
  | 'Play'
  | 'PlusThick'
  | 'Print'
  | 'PrinterOutline'
  | 'Proximity'
  | 'RoadVariant'
  | 'RoundedArrowLeft'
  | 'RoundedArrowRight'
  | 'Route'
  | 'Save'
  | 'Share'
  | 'Signal'
  | 'SimpleArrowRight'
  | 'Star'
  | 'ThumbUp'
  | 'Tick'
  | 'TrashCanOutline'
  | 'Travel'
  | 'TravelStep'
  | 'TrayArrowDown'
  | 'TrayArrowUp'
  | 'Triangle'
  | 'TriangleOutline'
  | 'Twitter'
  | 'Users'
  | 'VanShuttle'
  | 'ViewList'
  | 'Zoom';

export interface Identifier {
  '@id': string;
  id?: number;
  uuid: string;
}

export interface ImageDeleteForm {
  isMain: boolean;
  picture: Picture | null;
  revision: Revision | null;
  src: string | null;
}

/**
 * The available image filter types.
 *
 * - `default`: default aspect ratio
 * - `full`: 1920x1080
 * - `lg`: 700x700
 * - `md`: 300x300
 * - `sm`: 75x75
 * - `full_banner`: 1920x200
 * - `watermark`: Watermark filter
 */
export type ImageFilterType = 'full' | 'lg' | 'md' | 'full_banner' | 'watermark' | 'sm';

export const ImageFiltersBits: { [key in ImageFilterType]: number } = {
  full: 1,
  full_banner: 2,
  lg: 4,
  md: 8,
  sm: 32,
  watermark: 16,
};

export type ItemList<T = any> = Array<T>;

export interface LegalsTextType {
  body?: Array<LegalsTextType>;
  bold?: string;
  description?: string;
  list?: Array<LegalsTextType>;
  title?: string;
}

export interface Locale extends Identifier {
  alpha2: string;
  alpha3: string;
  code: string;
  iso: string;
}

export interface LoginForm {
  email: string | null;
  password: string | null;
}

export class MapCenter {
  lat: number = 45;
  lng: number = -0.5;
}

export class MapFilter {
  firstAverageGrade: boolean = false;
  maxHeight: string | null = null;
  maxWidth: string | null = null;
  maxLength: string | null = null;
  openAt: string = '';
  range: { max: number; min: number } = { max: 200, min: 0 };
  search?: string;
  searchEnd?: string;
  searchStart?: string;
  secondAverageGrade: boolean = false;
  services: Array<string> = [];
  types: Array<string> = [];
  unknownPrice: boolean = true;
}

export interface MapPoint {
  distance: number;
  duration: number;
  durationString?: string;
  location: google.maps.LatLng;
  name: string;
  placeId: string;
  poiUuid: string | null;
  pointOfInterest: PointOfInterest | string | null;
  position: number;
  revisionInitial: Revision | string | null;
}

export type MazPhoneEventType =
  | {
      countryCode: string;
      isValid: false;
      phoneNumber: string;
    }
  | {
      countryCallingCode: string;
      countryCode: string;
      e164: string;
      formatInternational: string;
      formatNational: string;
      isValid: true;
      nationalNumber: string;
      phoneNumber: string;
      uri: string;
    }
  | null
  | undefined;

export interface Media extends Identifier {
  author?: Person | null;
  contentUrl: string | null;
  filePath: string | null;
  fileSize: number | null;
  mimeType: string | null;
  originalName: string | null;
  position: number | null;
}

export class MediaForm {
  '@id': string | null = null;
  author: string | null = null;
  contentUrl: string | null = null;
  createdAt: string | null = null;
  file: File | null = null;
  filePath: string | null = null;
  fileSize: number | null = null;
  mimeType: string | null = null;
  originalName: string | null = null;

  constructor(createdAt: string | null = null) {
    this.createdAt = createdAt;
  }
}

export class MediaKitForm {
  address: string = '';
  company: string = '';
  fullname: string = '';
  telephone: string = '';
  knownType: Enumeration | null = null;
}

export type MetaProperty =
  | MetaPropertyCharset
  | MetaPropertyEquiv
  | MetaPropertyName
  | MetaPropertyMicrodata
  | MetaPropertyProperty;

export interface Metadata extends Identifier {
  description: string;
  keywords?: string;
  locale: Locale;
  name: string;
  title: string;
  url: string;
  uuid: string;
}

export interface NewsletterForm {
  activityValidated: boolean | null;
  addressValidated: boolean | null;
  areaCommented: boolean | null;
  areaValidated: boolean | null;
  commentReplied: boolean | null;
  commentValidated: boolean | null;
  messageReceived: boolean | null;
  premiumExpired: boolean | null;
  received: boolean | null;
}

export type OfferType = 'discovery';

export interface OpeningDate extends Identifier {
  end: string;
  formattedEnd: string;
  formattedStart: string;
  revision: string;
  start: string;
}

export class OpeningDateForm {
  '@id': string | null = null;
  end: string | null = moment().endOf('year').format('YYYY-MM-DD');
  revision: string;
  start: string | null = moment().startOf('year').format('YYYY-MM-DD');

  constructor(revisionId: string) {
    this.revision = revisionId;
  }

  hydrate(data: OpeningDate) {
    this['@id'] = data['@id'];
    this.end = data.formattedEnd;
    this.revision = data.revision;
    this.start = data.formattedStart;

    return this;
  }
}

export interface OpeningHour extends Identifier {
  end: string;
  formattedEnd: string;
  formattedStart: string;
  revision: string;
  start: string;
}

export class OpeningHourForm {
  '@id': string | null = null;
  end: string | null = '23:59';
  revision: string;
  start: string | null = '00:00';

  constructor(revisionId: string) {
    this.revision = revisionId;
  }

  hydrate(data: OpeningHour) {
    this['@id'] = data['@id'];
    this.end = data.formattedEnd;
    this.start = data.formattedStart;

    return this;
  }
}

export interface OpeningInformation extends Identifier {
  dates: Array<string>;
  description: string | null;
  hours: Array<string>;
}

export interface OptionInterface {
  descending: any;
  groupBy: Array<string>;
  groupDesc: Array<string>;
  itemsPerPage: number;
  multiSort: boolean;
  mustSort: boolean;
  page: number;
  sortBy: Array<string>;
  sortDesc: Array<string>;
  totalCarouselItems: number | null;
  totalItems: number | null;
}

export interface PaginatedHydraResponse<T = any> extends HydraResponse {
  'hydra:member': Array<T>;
  'hydra:totalItems': number;
  'hydra:view'?: HydraView;
}

export interface Partner extends Identifier {
  description: string | null;
  displayOrder: number | null;
  introduction: string | null;
  isSpecific: boolean;
  legals: string | null;
  locale: Locale;
  logo: Media | null;
  logoFile: File | null;
  popupDescription: string | null;
  popupError: string | null;
  popupLabel: string | null;
  popupTitle: string | null;
  slug: string | null;
  subTitle: string | null;
  title: string | null;
  url: string | null;
}

export interface Payment extends Identifier {
  activity: Activity | null;
  amount: number;
  createdAt: Date;
  currency: string;
  endAt: Date;
  invoiceId: string;
  pointOfInterest: PointOfInterest | null;
  startAt: Date;
  status: string;
  type: PaymentType;
}

export type PaymentType = 'account_premium' | 'activity_pro' | 'poi_discovery';

export interface Person extends Identifier {
  acceptedLegalNotices: boolean;
  activities: Array<Activity>;
  address: AddressForm | null;
  avatar: string | null | Media;
  birthDate: Date | null;
  camperVan: CamperVan | null;
  caras: number;
  civility: string | null;
  confirmPlainPassword: string | null;
  coverPicture: null | Media;
  coverPosition: number | null;
  createdAt: Date | null;
  description: string | null;
  email: string;
  enabled: boolean;
  endPremiumDate: Date;
  facebook: string | null;
  familyName: string;
  favorites: Array<Revision>;
  firstReference: PointOfInterest;
  fullName: string;
  givenName: string;
  instagram: string | null;
  newsletter: NewsletterForm | null;
  payments: Array<Payment>;
  plainPassword: string | null;
  privateFacebook: boolean | null;
  privateInstagram: boolean | null;
  roles: Array<string>;
  startedAt: Date | null;
  telephone: string;
  trips: string | null;
  vehicleType: Enumeration | null;
}

export interface PersonStat {
  nbAddedPoi: number;
  nbComments: number;
  nbPictures: number;
  nbUpdatedRevision: number;
}

export interface Picture extends Identifier {
  author?: Person | null;
  formattedValidatedDate: string | null;
  media: Media;
  pointOfInterest: string;
  position: number;
  type: 'proximity' | 'area';
  validatedDate: Date | null;
}

export interface PictureInformation {
  city: string;
  media: string;
  poiUuid: string;
  title: string;
}

export interface PictureOutput {
  name: string;
  picture: Picture;
  revision: Revision;
}

export interface PoiInformation {
  city: string;
  mainPicture: string;
  title: string;
  typeCode: string;
  uuid: string;
  zipCode: string | null;
}

export interface PointOfInterest extends Identifier {
  averageNotation: number;
  awards: Array<Award>;
  deletedAt: Date | null;
  isFavorite: boolean;
  mainPicture: Media | null;
  offerEndAt: Date | null;
  offerType: OfferType | null;
  pictures: Array<Picture>;
  referent?: Person;
  revision: Revision;
}

export interface PressKit extends Identifier {
  description: string | null;
  file: Media | null;
  formattedPublicationDate: string;
  locale: Locale;
  name: string | null;
  pressLink: string | null;
  publicationDate: string | null;
}

export interface PressRelease extends Identifier {
  extract: string | null;
  formattedPublicationDate: string;
  link: string | null;
  locale: Locale;
  logo: Media | null;
  logoFile: File | null;
  mediaType: Enumeration | null;
  pdf: Media | null;
  pdfFile: File | null;
  publicationDate?: string | null;
  title: string | null;
}

export interface PressStatement extends Identifier {
  file: Media | null;
  formattedPublicationDate: string;
  isVisible: boolean;
  locale: Locale;
  publicationDate?: string | null;
  title: string | null;
}

export interface PressVisualkit extends Identifier {
  file: Media | null;
  isVisible: boolean;
  locale: Locale;
  title: string | null;
}

export interface ProContactForm {
  companyName: string | null;
  email: string | null;
  fullname: string | null;
  message: string;
  phone: string | null;
}

export class ProContactFormObject implements ProContactForm {
  companyName: string | null = null;
  email: string | null = null;
  fullname: string | null = null;
  message: string = '';
  phone: string | null = null;
}

export class ProfileForm {
  '@id'?: string;
  id?: number;
  uuid?: string;
  address: AddressForm = new AddressForm();
  avatar: string | null | Media = null;
  birthDate: Date | null = null;
  camperVan: CamperVanForm | null = null;
  civility: string | null = null;
  confirmPlainPassword: string | null = null;
  coverPicture: string | null | Media = null;
  description: string | null = null;
  email: string | null = null;
  facebook: string | null = null;
  familyName: string | null = null;
  givenName: string | null = null;
  instagram: string | null = null;
  newsletter: NewsletterForm | null = null;
  plainPassword: string | null = null;
  privateFacebook: boolean | null = null;
  privateInstagram: boolean | null = null;
  startedAt: Date | null = null;
  telephone: string | null = null;
  trips: string | null = null;
  vehicleType: string | null = null;

  hydrate(data: Person) {
    this['@id'] = data['@id'];
    this.address = data.address || new AddressForm();
    this.avatar = data.avatar;
    this.birthDate = data.birthDate;
    this.camperVan = new CamperVanForm().hydrate(data.camperVan);
    this.civility = data.civility;
    this.confirmPlainPassword = data.confirmPlainPassword;
    this.coverPicture = data.coverPicture;
    this.description = data.description;
    this.email = data.email;
    this.facebook = data.facebook;
    this.familyName = data.familyName;
    this.givenName = data.givenName;
    this.instagram = data.instagram;
    this.newsletter = data.newsletter;
    this.plainPassword = data.plainPassword;
    this.privateFacebook = data.privateFacebook;
    this.privateInstagram = data.privateInstagram;
    this.startedAt = data.startedAt;
    this.telephone = data.telephone;
    this.trips = data.trips;
    this.vehicleType = data.vehicleType ? data.vehicleType['@id'] : null;
    this.newsletter = data.newsletter || {
      activityValidated: true,
      addressValidated: true,
      areaCommented: true,
      areaValidated: true,
      commentReplied: true,
      commentValidated: true,
      messageReceived: true,
      premiumExpired: true,
      received: true,
    };

    return this;
  }
}

export interface RandomPersonOutput {
  '@id': string;
  avatar: null | Media;
  caras: string;
  familyName: string;
  givenName: string;
  uuid: string;
}

export interface RangeSelectFilterType {
  default: string;
  formatter: (value: any) => string;
  max: number;
  min: number;
  precision?: number;
  step: number;
}

export interface RangeType {
  max: number;
  min: number;
  precision?: number;
  step: number;
}

export interface RankOutput {
  person: Person;
  rank: number;
}

export class RegisterForm {
  acceptedLegalNotices: boolean = false;
  confirmPlainPassword: string | null = null;
  email: string | null = null;
  familyName: string | null = null;
  givenName: string | null = null;
  plainPassword: string | null = null;
  terminal: string | null = null;
  newsletter: boolean = false;
}

export interface RequestPasswordForm {
  email: string | null;
  url: string | null;
}

export interface ResetPasswordForm {
  confirmPlainPassword: string | null;
  plainPassword: string | null;
}

export interface Revision extends Identifier {
  address: Address;
  attributes: Array<RevisionAttribute>;
  campingInformation: CampingInformation;
  contactInformation: ContactInformation;
  currency: Enumeration | null;
  description: string | null;
  floorQuality: Enumeration | null;
  floorType: Enumeration | null;
  internalNote: string | null;
  isTemporaryClosed: boolean | null;
  languages: Array<RevisionAttribute>;
  main: boolean;
  maxHeight: number | null;
  maxLength: number | null;
  maxWidth: number | null;
  name: string;
  nbCarPlace: number | null;
  nbChargingPoint: number | null;
  network: string | null;
  number: number | null;
  openingDates: Array<OpeningDate>;
  openingDescription: string | null;
  openingHours: Array<OpeningHour>;
  openingInformation: OpeningInformation;
  parkingTime: Enumeration | null;
  parkingType?: Enumeration | null;
  paymentTypes: Array<Enumeration>;
  places: Array<RevisionAttribute>;
  pointOfInterest: PointOfInterest;
  prices: Array<RevisionAttribute>;
  proximity: string | null;
  services: Array<RevisionAttribute>;
  sources: Array<RevisionAttribute>;
  temporaryClosed: boolean;
  temporaryClosedReason: string | null;
  translations: { [key: string]: { description: string; locale: string } };
  type: Enumeration<RevisionCodeType>;
}

export interface RevisionAttribute extends Identifier {
  attribute: Attribute;
  category: string;
  detail: number | null;
  label: string;
}

export interface RevisionAttributeForm {
  '@id'?: string;
  attribute: string;
  category?: string;
  detail: number | null;
  id?: number;
  picto: Media | null;
  revision?: string;
  uuid?: string;
}

export class RevisionAttributeFormObject implements RevisionAttributeForm {
  '@id'?: string;
  id?: number;
  uuid?: string;
  attribute: string;
  category?: string;
  picto: Media | null = null;
  revision?: string;
  detail: number | null = 0;

  constructor(attribute: Attribute, revision: string) {
    this.attribute = attribute['@id'];
    this.picto = attribute.picto;
    this.revision = revision;
  }

  hydrate(revisionAttribute: RevisionAttribute) {
    this['@id'] = revisionAttribute['@id'];
    this.attribute = revisionAttribute.attribute['@id'];
    this.category = revisionAttribute.category;
    this.detail = revisionAttribute.detail;
    this.picto = revisionAttribute.attribute.picto;

    return this;
  }
}

export class RevisionEmailShareForm extends EmailShareForm {
  revision: string = '';

  constructor(revision: Revision) {
    super();

    this.revision = revision['@id'];
  }
}

export interface RevisionFilter {
  'address.country'?: string | null;
  'address.lat'?: number | null;
  'address.lng'?: number | null;
  'address.streetName'?: string | null;
  'attributes.attribute.uuid'?: Array<string> | null;
  attributesDetail?: Array<number> | null;
  firstAverageGrade?: boolean | null;
  id?: number | null;
  maxHeight?: string | null;
  maxLength?: string | null;
  maxRange?: number | null;
  maxWidth?: string | null;
  minRange?: number | null;
  name?: string | null;
  openingDate?: string | null;
  page?: number | null;
  'pointOfInterest.author.id'?: number | null;
  'pointOfInterest.id'?: number | null;
  search?: string | null;
  secondAverageGrade?: boolean | null;
  'type.uuid'?: Array<string> | null;
}

export interface RevisionForm {
  '@id': string | null;
  address: AddressForm;
  attributes: Array<RevisionAttributeForm>;
  brands: Array<RevisionAttributeForm>;
  campingInformation: CampingInformationForm;
  contactInformation: ContactInformationForm;
  currency: string | null;
  description: string | null;
  floorQuality: string | null;
  floorType: string | null;
  internalNote: string | null;
  labelLink: string | null;
  labels: Array<string>;
  languages: Array<RevisionAttributeForm>;
  maxHeight: number | null;
  maxLength: number | null;
  maxWidth: number | null;
  name: string | null;
  nbCarPlace: number | null;
  nbChargingPoint: number | null;
  network: string | Enumeration | null;
  number: number | null;
  openingDates: Array<OpeningDateForm>;
  openingDescription: string | null;
  openingHours: Array<OpeningHourForm>;
  parkingTime: string | null;
  parkingType: string | null;
  paymentTypes: Array<string>;
  places: Array<RevisionAttributeForm>;
  pointOfInterest: string | null;
  prices: Array<RevisionAttributeForm>;
  proximity: string | null;
  referent: string | null;
  services: Array<RevisionAttributeForm>;
  sources: Array<RevisionAttributeForm>;
  translations: { [key: string]: { description: string; locale: string } };
  type: string | null;
}

export class RevisionFormObject implements RevisionForm {
  '@id': string | null = null;
  uuid: string | null = null;

  address: AddressForm = {
    addressAddIn: null,
    cedex: null,
    cityCode: null,
    cityName: '',
    country: '',
    isoCode: null,
    lat: 0,
    lat1: null,
    lat2: null,
    lat3: null,
    lng: 0,
    lng1: null,
    lng2: null,
    lng3: null,
    streetName: '',
    zipCode: '',
  };

  attributes: Array<RevisionAttributeFormObject> = [];
  brands: Array<RevisionAttributeFormObject> = [];
  campingInformation: CampingInformationForm = {
    animal: false,
    barbecue: false,
    ccPassage: false,
    ffcAdvantage: false,
    ffcAdvantageDescription: null,
    mandatoryParking: false,
    minimumSleep: null,
    naturist: false,
    notation: null,
    stopWelcome: false,
    stopWelcomeDescription: null,
    surface: null,
  };

  contactInformation: ContactInformationForm = {
    cityHall: null,
    email: null,
    email2: null,
    facebook: null,
    instagram: null,
    policeOffice: null,
    referentName: null,
    telephone: null,
    telephone2: null,
    touristOffice: null,
    twitter: null,
    website: null,
    website2: null,
  };

  currency: string | null = null;
  description: string | null = null;
  floorQuality: string | null = null;
  floorType: string | null = null;
  internalNote: string | null = null;
  labels: Array<string> = [];
  labelLink: string | null = null;
  languages: Array<RevisionAttributeFormObject> = [];
  main: boolean = false;
  maxLength: number | null = null;
  maxHeight: number | null = null;
  maxWidth: number | null = null;
  name: string | null = null;
  network: string | Enumeration | null = null;
  nbCarPlace: number | null = null;
  nbChargingPoint: number | null = null;
  number: number | null = null;
  openingDates: Array<OpeningDateForm> = [];

  openingDescription: string | null = null;
  openingHours: Array<OpeningHourForm> = [];

  parkingTime: string | null = null;
  parkingType: string | null = null;
  paymentTypes: Array<string> = [];
  places: Array<RevisionAttributeFormObject> = [];
  pointOfInterest: string | null = null;
  pointOfInterestUuid: string | null = null;

  proximity: string | null = null;
  prices: Array<RevisionAttributeFormObject> = [];
  referent: string | null = null;
  services: Array<RevisionAttributeFormObject> = [];
  sources: Array<RevisionAttributeFormObject> = [];
  type: string | null = null;

  isTemporaryClosed: boolean = false;
  temporaryClosedReason: string | null = null;
  temporaryClosedAlreadyFilled: boolean = false;
  translations: { [key: string]: { description: string; locale: string } } = {};

  public hydrate(revision: Revision, locale: string) {
    this['@id'] = revision['@id'];
    this.uuid = revision.uuid;
    this.address = revision.address;
    this.brands = revision.attributes
      .filter((item) => item.category === Constant.ATTRIBUTE_CATEGORY_BRAND)
      .map((item) => new RevisionAttributeFormObject(item.attribute, revision['@id']).hydrate(item));
    this.campingInformation = revision.campingInformation;
    this.contactInformation = revision.contactInformation;
    this.currency = revision.currency ? revision.currency['@id'] : null;
    this.description = revision.description;
    this.floorQuality = revision.floorQuality ? revision.floorQuality['@id'] : null;
    this.floorType = revision.floorType ? revision.floorType['@id'] : null;
    this.internalNote = revision.internalNote;
    this.labelLink = null;
    this.labels = [];
    this.languages = revision.attributes
      .filter((item) => item.category === Constant.ATTRIBUTE_CATEGORY_LANGUAGE)
      .map((item) => new RevisionAttributeFormObject(item.attribute, revision['@id']).hydrate(item));
    this.main = revision.main;
    this.maxHeight = revision.maxHeight;
    this.maxLength = revision.maxLength;
    this.maxWidth = revision.maxWidth;
    this.name = revision.name;
    this.nbCarPlace = revision.nbCarPlace;
    this.nbChargingPoint = revision.nbChargingPoint;
    this.network = revision.network;
    this.number = revision.number;
    this.openingDates = revision.openingDates.map((openingDate) =>
      new OpeningDateForm(revision['@id']).hydrate(openingDate),
    );
    this.openingDescription = revision.openingDescription;
    this.openingHours = revision.openingHours.map((openingHour) =>
      new OpeningHourForm(revision['@id']).hydrate(openingHour),
    );
    this.parkingTime = revision.parkingTime ? revision.parkingTime['@id'] : null;
    this.parkingType = revision.parkingType ? revision.parkingType['@id'] : null;
    this.paymentTypes = revision.paymentTypes ? revision.paymentTypes.map((enumeration) => enumeration['@id']) : [];
    this.places = revision.attributes
      .filter((item) => item.category === Constant.ATTRIBUTE_CATEGORY_PLACE)
      .map((item) => new RevisionAttributeFormObject(item.attribute, revision['@id']).hydrate(item));
    this.pointOfInterest = revision.pointOfInterest['@id'] || null;
    this.pointOfInterestUuid = revision.pointOfInterest.uuid || null;
    this.prices = revision.attributes
      .filter((item) => item.category === Constant.ATTRIBUTE_CATEGORY_PRICE)
      .map((item) => new RevisionAttributeFormObject(item.attribute, revision['@id']).hydrate(item));
    this.proximity = revision.proximity;
    this.services = revision.attributes
      .filter((item) => item.category === Constant.ATTRIBUTE_CATEGORY_SERVICE)
      .map((item) => new RevisionAttributeFormObject(item.attribute, revision['@id']).hydrate(item));
    this.sources = revision.attributes
      .filter((item) => item.category === Constant.ATTRIBUTE_CATEGORY_SOURCES)
      .map((item) => new RevisionAttributeFormObject(item.attribute, revision['@id']).hydrate(item));
    this.type = revision.type ? revision.type['@id'] : null;
    this.isTemporaryClosed = revision.isTemporaryClosed || false;
    this.temporaryClosedAlreadyFilled = revision.isTemporaryClosed || false;

    this.translations = revision.translations;
    if (this.translations.hasOwnProperty(locale)) this.description = this.translations[locale].description;

    return this;
  }

  public sanitize() {
    const clone = JSON.parse(JSON.stringify(this));

    delete clone['@id'];
    delete clone.uuid;

    delete clone.pointOfInterestUuid;

    if (clone.network && typeof clone.network !== 'string') clone.network = clone.network['@id'];

    if (clone.contactInformation) {
      delete clone.contactInformation['@id'];
      delete clone.contactInformation['@type'];
    }
    if (clone.campingInformation) {
      delete clone.campingInformation['@id'];
      delete clone.campingInformation['@type'];
    }
    if (clone.address) {
      delete clone.address['@id'];
      delete clone.address['@type'];
    }

    clone.attributes = clone.services
      .concat(clone.brands, clone.languages, clone.places, clone.prices)
      .map((attr: RevisionAttributeFormObject) => {
        delete attr.uuid;
        delete attr['@id'];
        // @ts-ignore
        delete attr.picto;
        return attr;
      });

    clone.openingDates = clone.openingDates.map((o: any) => {
      delete o['@id'];
      delete o.id;
      delete o.uuid;
      return o;
    });

    clone.openingHours = clone.openingHours.map((o: any) => {
      delete o['@id'];
      delete o.id;
      delete o.uuid;
      return o;
    });

    delete clone.brands;
    delete clone.languages;
    delete clone.places;
    delete clone.prices;

    clone.translations = Object.keys(clone.translations).map((key) => {
      return {
        description: clone.translations[key].description,
        locale: key,
      };
    });

    return clone;
  }
}
export type SForm = Vue & { validate: () => boolean };

export interface SelectEntityEvent {
  activity?: Activity;
  id?: string;
  revision?: Revision;
}

export interface SelectOption<T = any> {
  label: any;
  value: T;
}

export class ShareTripForm extends FormClass<any> {
  title: string | null = null;
  description: string | null = null;
  categories: Array<Enumeration> = [];
  trip: string | null = null;

  constructor(trip: TripClass) {
    super();
    this.trip = trip['@id'];
  }

  format(): object {
    const obj = super.format();

    if ('@id' in obj) delete (obj as any)['@id'];

    return obj;
  }
}

export interface Step extends Translatable<StepTranslation>, Omit<StepTranslation, 'locale' | 'id'> {
  notation: number;
  photo: Media | null;
  status: boolean;
  url: string | null;
}

export interface StepTranslation extends Translation {
  name: string | null;
}

export type StringFormatterType = (str: string) => string;

export interface StripeProForm {
  cardCvcElement: StripeCardCvcElement | null;
  cardExpiryElement: StripeCardExpiryElement | null;
  cardNumberElement: StripeCardNumberElement | null;
  cardOwnerElement: string;
}

export class StripeProFormObject implements StripeProForm {
  cardCvcElement: StripeCardCvcElement | null = null;
  cardExpiryElement: StripeCardExpiryElement | null = null;
  cardNumberElement: StripeCardNumberElement | null = null;
  cardOwnerElement: string = '';
}

export interface SuggestionForm {
  email: string | null;
  message: string;
  poiId: number;
  poiName: string;
}

export class SuggestionFormObject implements SuggestionForm {
  email: string | null = null;
  message: string = '';
  poiId: number = 0;
  poiName: string = '';
}

export interface TeamMember extends Identifier {
  email: string | null;
  familyName: string;
  givenName: string;
  isCaramapsEmployee: boolean;
  locale: Locale | null;
  photo1: Media | null;
  photo2: Media | null;
  position: number;
  telephone: string | null;
  translations: { [key: string]: TeamMemberTranslation };
}

export interface TeamMemberTranslation {
  description: string | null;
  id: string | null;
  jobTitle: string | null;
  locale: string | null;
}

export interface TemporaryClosure {
  end?: string | null;
  reason: string;
  start: string;
}

export interface Translatable<T extends Translation> extends Identifier {
  translations: DictionnaryType<T>;
}

export class TranslatableClass<T extends Translatable<U>, U extends Translation> extends FormClass<T> {
  translations: DictionnaryType<U> = {};
  emptyTranslation: ((alpha2: string) => U) | undefined;

  tHydrate(entity: T, locales: Array<Locale>) {
    super.hydrate(entity);

    this.hydrateTranslations(locales);
  }

  hydrateTranslations(locales: Array<Locale>): void {
    locales.forEach((locale) => {
      if (this.emptyTranslation) this.translations[locale.alpha2] = this.emptyTranslation(locale.alpha2);
    });
  }
}

export interface Translation {
  locale: string | null;
}

export interface Trip extends Translatable<TripTranslation> {
  author: Person | null;
  authorName: string | null;
  autoSave: boolean;
  averageNotation: number;
  avoidHighways: boolean;
  banner: Media | string | null;
  clonedCount: number;
  createdAt: Date | null;
  description: string | null;
  directionEnd: MapPoint | null;
  directionStart: MapPoint | null;
  directionWaypoints: Array<MapPoint>;
  durationString?: string;
  endDate: Date | null;
  errorCouple: Array<[{ lat: number; lng: number }, { lat: number; lng: number }]>;
  name: string;
  nextTrip: string | null;
  photo: Media | string | null;
  polylines: Array<string>;
  previousTrip: string | null;
  startDate: Date | null;
  stepsCount: number;
  totalDistance: number;
  totalDuration: number;
  updatedAt: Date | null;
}

export interface TripCategory extends Identifier {
  logo: Media | null;
  logoFile: File | null;
  name: string | null;
  photo: Media | null;
  photoFile: File | null;
  translations: { [key: string]: TripCategoryTranslation };
}

export interface TripCategoryTranslation {
  id: string | null;
  locale: string | null;
  name: string | null;
}

export class TripClass extends TranslatableClass<Trip, TripTranslation> {
  author: Person | null = null;
  authorName: string | null = null;
  autoSave: boolean = false;
  avoidHighways: boolean = false;
  createdAt: Date | null = null;
  description: string = '';
  directionEnd: MapPoint | null = null;
  directionStart: MapPoint | null = null;
  directionWaypoints: Array<MapPoint> = [];
  durationString?: string;
  endDate: string | null = null;
  errorCouple: Array<[{ lat: number; lng: number }, { lat: number; lng: number }]> = [];
  name: string;
  photo: Media | null = null;
  startDate: string | null = null;
  totalDistance = 0;
  stepsCount = 0;
  totalDuration = 0;
  updatedAt: Date | null = null;
  uuid: string = '';
  polylines: Array<string> = [];

  constructor(name: string) {
    super();

    this.name = name;
  }

  emptyTranslation = (alpha2: string): TripTranslation => ({
    locale: alpha2,
    name: this.translations[alpha2]?.name || this.name || '',
  });

  format(): object {
    const obj = super.format() as TripClass;
    Object.entries(obj.translations).forEach(([locale, translation]) => {
      translation.name = obj.name;
      obj.translations[locale] = translation;
    });
    delete (obj as any).name;

    return obj;
  }
}

export interface TripComment extends Comment {
  trip: Trip | null;
}

export interface TripCommentForm {
  notation: number;
  trip: string;
  value: string;
}

export interface TripCountry extends Identifier {
  name: string | null;
  photo: Media | string | null;
  photoFile: File | null;
  translations: { [key: string]: TripCountryTranslation };
}

export interface TripCountryTranslation {
  id: string | null;
  locale: string | null;
  name: string | null;
  slug: string | null;
}

export type TripSortType = 'createdAt' | 'stepsCount' | 'totalDistance' | null;

export interface TripTranslation extends Translation {
  name: string;
}

export interface Weather {
  cloud: number;
  date: string;
  day: string;
  dayTemperature: number;
  eveningTemperature: number;
  humidity: number;
  maxTemperature: number;
  minTemperature: number;
  morningTemperature: number;
  nightTemperature: number;
  pressure: number;
  timestamp: number;
  weatherCode: string;
  weatherDescription: string;
  weatherIcon: string;
  weatherId: number;
  weatherText: string;
  windAngle: number;
  windSpeed: number;
}

export interface WebAd extends Ad, Translatable<WebAdTranslation> {
  popupType: WebPopupAdType | null;
  target: WebAdTargetType;
  type: WebAdType;
  url: string;
}

export type WebAdTargetType = 'target_user' | 'target_everyone';

export interface WebAdTranslation extends Translation {
  photo: Media | string | null;
  photoFileObject: File | null;
}

export type WebAdType = 'web_banner' | 'web_map' | 'web_popup';

export type WebPopupAdType = 'once' | 'daily';
