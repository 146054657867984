import { AxiosRequestConfig, CancelToken, CancelTokenSource, CancelTokenStatic } from 'axios';
import Api from './Api';
import { Entrypoints } from '~/ts/config/entrypoints';
import { DictionnaryType, ElasticSearchTripResult, ShareTripForm, Trip, TripClass } from '~/ts/interfaces/types';
import { $axios } from '~/ts/utils/api';

export class TripApi extends Api<TripClass> {
  private cancelToken: CancelTokenStatic;
  private source: CancelTokenSource;

  constructor() {
    super(Entrypoints.Trip);
    this.cancelToken = $axios.CancelToken;
    this.source = this.cancelToken.source();
  }

  create(travel: TripClass, config: AxiosRequestConfig | undefined = undefined): Promise<TripClass> {
    this.source?.cancel();
    this.source = this.cancelToken.source();

    config = { ...config, cancelToken: this.source?.token };

    return new Promise<TripClass>((resolve) => {
      super
        .create(travel.format(), config)
        .then((res) => {
          if (!res) return resolve(travel);
          return resolve(new TripClass('').hydrate(res));
        })
        .catch((err) => {
          this.logError(err);
          return resolve(travel);
        });
    });
  }

  async findByUuid(uuid: any): Promise<any> {
    return await super.find(`api/${this.endpoint}/${uuid}`).then((response: TripClass) => {
      const trip = new TripClass('');
      trip.hydrate(response);
      trip.directionWaypoints = trip.directionWaypoints.sort((a, b) => a.position - b.position);
      return trip;
    });
  }

  update(entrypoint: string, travel: TripClass): Promise<TripClass> {
    this.source?.cancel();
    this.source = this.cancelToken.source();

    return new Promise<TripClass>((resolve) => {
      $axios
        .$put(entrypoint, travel.format(), { cancelToken: this.source.token })
        .then((res) => {
          if (!res) return resolve(travel);
          return resolve(new TripClass('').hydrate(res));
        })
        .catch((err) => {
          this.logError(err);
          return resolve(travel);
        });
    });
  }

  async shareTrip(form: ShareTripForm): Promise<void> {
    return await $axios.$post(`/api/${this.endpoint}/share`, form.format()).catch(this.logError);
  }

  getPdf(trip: TripClass | Trip): Promise<Blob> {
    return $axios.$get(`api/${this.endpoint}/${trip.uuid}/pdf `, {
      responseType: 'blob',
    });
  }

  searchElastic(
    filters: DictionnaryType,
    page: number = 1,
    cancelToken: CancelToken | undefined = undefined,
    params: DictionnaryType = {},
  ): Promise<ElasticSearchTripResult> {
    console.log(this.formatElasticQuery(filters, page, params));

    return $axios.$get(`/api/elastic_trips${this.formatElasticQuery(filters, page, params)}`, { cancelToken });
  }
}
